var tri = require('tripartite')
let formArea = document.querySelector('#form-application')
let counts = {
	employer: 0
	, license: 0
}

function addContent(parentSelector, templateName, counterName) {
	let parent = formArea.querySelector(parentSelector)
	let holder = document.createElement('div')
	holder.innerHTML = tri.getTemplate(templateName)({ number: getNextCounter(counterName)})
	let child = holder.querySelector('div')
	parent.insertAdjacentElement('beforeend', child)
	let input = child.querySelector('input')
	if(input) {
		input.focus()
	}
}

function getNextCounter(counter) {
	let number
	if(counter in counts) {
		number = counts[counter]
	}
	else {
		number = -1
	}
	
	number++
	counts[counter] = number
	return number
}
if (formArea) {
	formArea.innerHTML =
		tri.getTemplate('views/personal-information')({})
		+ tri.getTemplate('views/position-desired')({})
		+ tri.getTemplate('views/drivers-license-history')({})
		+ '<div class="practical-experience xs12" data-section-name="Practical Experience" >'
		+ tri.getTemplate('views/vehicle-history')({})
		+ tri.getTemplate('views/equipment-history')({})
		+ tri.getTemplate('views/accident-history')({})
		+ tri.getTemplate('views/violation-history')({})
		+ '</div>'
		+ tri.getTemplate('views/education-information')({})
		+ tri.getTemplate('views/employment-history')({})
		+ tri.getTemplate('views/references')({})
		+ tri.getTemplate('views/signature-block')({})

	formArea.addEventListener('click', (evt) => {
		if (evt.target.classList.contains('add-employer')) {
			addContent('.employers', 'views/employment-history-position', 'employer')
		}
		else if (evt.target.classList.contains('add-license')) {
			addContent('.licenses', 'views/drivers-license', 'license')
		}
		else if (evt.target.classList.contains('add-vehicle')) {
			addContent('.vehicles', 'views/vehicle', 'vehicle')
		}
		else if (evt.target.classList.contains('add-equipment')) {
			addContent('.equipment', 'views/equipment', 'equipment')
		}
		else if (evt.target.classList.contains('add-accident')) {
			addContent('.accidents', 'views/accident', 'accident')
		}
		else if (evt.target.classList.contains('add-violation')) {
			addContent('.violations', 'views/violation', 'violation')
		}
	})
	let applicationControls = document.querySelector('.application-form')
	if (applicationControls) {
		let form = document.querySelector('#apply')
		let header = document.querySelector('#form-header')
		let footer = document.querySelector('#form-footer')
		let children = [...applicationControls.children]
		let names = children.map(el => el.getAttribute('data-section-name'))

		function makeActive(sectionName) {
			footer.classList.remove('start')
			footer.classList.remove('mid')
			footer.classList.remove('end')
			document.querySelectorAll('[data-section-name]').forEach(section => {
				section.classList.remove('current')
			})

			document.querySelectorAll('[data-section-name="' + sectionName + '"]').forEach(section => {
				section.classList.add('current')
				let first = section.querySelector('input')
				if(first) {
					first.focus()
				}
			})

			if (names.indexOf(sectionName) == 0) {
				footer.classList.add('start')
			}
			else if (names.indexOf(sectionName) == names.length - 1) {
				footer.classList.add('end')
			}
			else {
				footer.classList.add('mid')
			}
			header.scrollIntoView(true)
		}


		names.forEach(name => {
			header.insertAdjacentHTML('beforeend', `<div class="section" data-section-name="${name}">${name}</div>`)
		})

		header.querySelector('.section').classList.add('current')
		makeActive(names[0])

		header.querySelectorAll('.section').forEach(section => {
			section.addEventListener('click', (evt) => {
				makeActive(section.getAttribute('data-section-name'))
			})
		})


		footer.querySelector('.next').addEventListener('click', (evt) => {
			evt.preventDefault()

			let currentSection = document.querySelector('.section.current').getAttribute('data-section-name')
			let position = names.indexOf(currentSection)
			let nextPosition = position + 1
			makeActive(names[nextPosition])
		})
		footer.querySelector('.previous').addEventListener('click', (evt) => {
			evt.preventDefault()

			let currentSection = document.querySelector('.section.current').getAttribute('data-section-name')
			let position = names.indexOf(currentSection)
			let nextPosition = position - 1
			makeActive(names[nextPosition])
		})
		footer.querySelector('.submit').addEventListener('click', (evt) => {
			// evt.preventDefault()

			console.log('submit')
		})

		form.addEventListener('keydown', (evt) => {
			if (evt.keyCode == 13) {
				evt.preventDefault();
				return false;
			}
		})
	}
}




