window.require = require
window.jQuery = window.$ = require('jquery')

var tri = require('tripartite')
var tripartite = tri

require('../views/load-client-templates')
const recaptchaSetup = require('webhandle-emailer/client-js/make-form-recaptcha')

const SwipeListener = require('swipe-listener')
const Shiner = require('shiner/shiner-no-jquery')($)
let createShows = require('shiner/create-shows')


$(function () {
	let page = document.querySelector('.page-landing')
	if (page) {
		createShows(Shiner, SwipeListener).forEach(shine => shine.shineOn())
	}
	page = document.querySelector('.page-our-work')
	if (page) {
		//createShows(Shiner, SwipeListener, { slideDelay: 1000} ).forEach(shine => shine.shineOn())
		let shines = createShows(Shiner, SwipeListener, { slideDelay: 1000 })
		document.querySelectorAll('.shiner-show').forEach(show => {
			show.addEventListener('mouseenter', evt => {
				show.shine.go()
				show.shine.next()
			})
			show.addEventListener('mouseleave', evt => {
				show.shine.stop()
			})
		})
	}
	$('header .tribar').on('click', function (evt) {
		evt.preventDefault()
		$('header nav').toggleClass('open')
		$('body').toggleClass('locked');
	})
	$('header .menu li').removeClass('current')
	$('header .menu a').each(function () {
		if ($(this).attr('href') == window.location.pathname) {
			$(this).closest('li').addClass('current')
		}
	})

	function makeValidMeasure(measure) {
		try {
			if(measure === null || measure === undefined) {
				return null
			}
			
			let num = parseFloat(measure)
			if (Number.isNaN(num)) {
				return null
			}
			
			if (num <= 0) {
				return null
			}
			return num

		}
		catch (e) {
			return null
		}
	}
	let aggregateCalculator = document.querySelector('form.aggregate-calculator')
	if (aggregateCalculator) {
		// density in kg/m3
		let defaultDensity = 1520
		let cubicYardsInCubicMeter = 1.30795
		

		let widthInput = aggregateCalculator.querySelector('[name=width]')
		let heightInput = aggregateCalculator.querySelector('[name=height]')
		let depthInput = aggregateCalculator.querySelector('[name=depth]')
		
		let widthUnit = aggregateCalculator.querySelector('[name=widthUnit]')
		let heightUnit = aggregateCalculator.querySelector('[name=heightUnit]')
		let depthUnit = aggregateCalculator.querySelector('[name=depthUnit]')
		

		let msg = aggregateCalculator.querySelector('.msg')
		let resultBox = aggregateCalculator.querySelector('.result')
		let tons = aggregateCalculator.querySelector('#tons')
		let yards = aggregateCalculator.querySelector('#yards')
		

		aggregateCalculator.addEventListener('submit', (evt) => {
			evt.preventDefault()
			msg.innerHTML = ''
			tons.value = ''
			yards.value = ''

			let width = makeValidMeasure(widthInput.value)
			if(!width) {
				msg.innerHTML = 'Please enter a valid width'
				return
			}
			let height = makeValidMeasure(heightInput.value)
			if(!height) {
				msg.innerHTML = 'Please enter a valid height'
				return
			}
			let depth = makeValidMeasure(depthInput.value)
			if(!depth) {
				msg.innerHTML = 'Please enter a valid depth'
				return
			}
			
			let volume =
				width * parseFloat(widthUnit.value) *
				height * parseFloat(heightUnit.value) *
				depth * parseFloat(depthUnit.value)
			
			let weightKg = volume * defaultDensity * 2.2 / 2000
			tons.innerHTML = weightKg.toFixed(3)	
			
			yards.innerHTML = (cubicYardsInCubicMeter * volume).toFixed(3)
			
			resultBox.classList.add('show')
		})
	}
	
	if (window.recaptchaId && $('form.google-recaptcha-form').length > 0) {
		recaptchaSetup(window.recaptchaId)
	}
	
	require('./application-form')
})


