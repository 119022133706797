var tri = require('tripartite')

require('./personal-information.tri')
require('./education-information.tri')
require('./position-desired.tri')
require('./employment-history.tri')
require('./employment-history-position.tri')
require('./drivers-license-history.tri')
require('./drivers-license.tri')
require('./vehicle-history.tri')
require('./vehicle.tri')
require('./equipment-history.tri')
require('./equipment.tri')
require('./accident-history.tri')
require('./accident.tri')
require('./violation-history.tri')
require('./violation.tri')
require('./references.tri')
require('./reference.tri')
require('./signature-block.tri')